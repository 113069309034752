/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    mailbox2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9 8.5h2.793l.853.854A.5.5 0 0013 9.5h1a.5.5 0 00.5-.5V8a.5.5 0 00-.5-.5H9z"/><path pid="1" d="M12 3H4a4 4 0 00-4 4v6a1 1 0 001 1h14a1 1 0 001-1V7a4 4 0 00-4-4M8 7a4 4 0 00-1.354-3H12a3 3 0 013 3v6H8zm-3.415.157C4.42 7.087 4.218 7 4 7s-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 012 0c0 .334-.164.264-.415.157"/>',
    },
});
